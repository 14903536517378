/* eslint-disable no-return-assign */
import React from 'react';
import { Link } from 'gatsby';
import * as Styles from '../styles/components/header.module.scss';
import RoundButton from './roundedButton';
import Logo from '../images/locallylink_black_cursive_text.svg';

const Header = (props) => (
    <header>
        <nav className={Styles.header}>
            <div className={Styles.container}>
                <Link className={Styles.logoLink} to="/"><img className={Styles.logo} src={Logo} alt="Locally Links logo" width="200px"/></Link>
                <div className={Styles.navContent}>
                    <div className={Styles.hyperlinks}>
                        {props.links.map((link, k) => <Link key={k} to={link}>{props.headings[k]} </Link>)}
                    </div>
                    <RoundButton classNames={Styles.login} style="white" href="https://builder.locally.link/login" text="Login" />
                    <RoundButton classNames={Styles.signup} style="gradient" href="https://builder.locally.link/register" text="Sign Up" />
                </div>
            </div>
        </nav>
    </header>
);
export default Header;
