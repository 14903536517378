import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from '../styles/components/roundedButton.module.scss';

const RoundButton = (props) => (
    <a className={`${Styles[props.style]} ${props.classNames != null ? props.classNames : ''}`} href={props.href}>{props.text}</a>
);

RoundButton.propTypes = {
    href: PropTypes.string.isRequired,
    style: PropTypes.oneOf(['white', 'gradient']).isRequired,
    text: PropTypes.string,
};

export default RoundButton;
