import React from 'react';
import { Link } from 'gatsby';
import Header from './header';
import Footer from './footer';
import 'normalize.css';

const Layout = ({ location, title, children }) =>
{
    let header; let
        footer;
    if (location === 'home')
    {
        header = (
            <Header
                links={['/', '#features', '#pricing']}
                headings={['Home', 'Features', 'Pricing']}
            />
        );
        footer = (
            <Footer
                backgroundColor="#F7FAFC"
            />
        );
    }
    else
    {
        header = (
            <Header
                links={['/']}
                headings={['Home']}
            />
        );
        footer = <Footer/>;
    }
    return (
        <>
            {header}
            <main>{children}</main>
            {footer}
        </>
    );
};

export default Layout;
